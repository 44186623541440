import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'animate.css/animate.min.css'
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-awesome/icons'
import 'vue-awesome/icons/spinner'
import Icon from 'vue-awesome/components/Icon'
import Particles from "particles.vue"; 
import i18n from '@/i18n';

Vue.use(Particles);

Vue.config.productionTip = false

Vue.use(VueAxios, axios)   
Vue.use(BootstrapVue) 
Vue.component('icon', Icon) 
Vue.component('v-icon', Icon)

let lang = localStorage.getItem("lang");

    
    if(lang == null || 
        lang == undefined ||
        lang == '') {
         i18n.locale = "en";
      } else {
        i18n.locale = lang;
      }



Object.defineProperty(Vue.prototype, 'i18n', { value: i18n })

axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'; 
axios.defaults.headers.common['blox-authz'] = localStorage.getItem("tokenId");
  
new Vue({
  router,
  i18n,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

Vue.filter('truncate', function (value, limit) {
  if (value.length > limit) {
      value = value.substring(0, (limit - 3)) + '...';
  }

  return value
})

