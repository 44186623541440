import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../components/Login.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../components/Signup.vue')
  },
  {
    path: '/setup-profile',
    name: 'SetupProfile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "setup-profile" */ '../components/SetupProfile.vue')
  },
  {
    path: '/setup-wallet',
    name: 'SetupWallet',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "setup-wallet" */ '../components/SetupWallet.vue')
  },
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('../components/Dashboard.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../components/Profile.vue')
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: () => import('../components/Transactions.vue')
  },
  {
    path: '/token/:token',
    name: 'AssetDashboard',
    props:true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dashboard" */ '../components/AssetDashboard.vue')
  },
  {
    path: '/nft/:nft',
    name: 'NftDashboard',
    props:true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dashboard" */ '../components/NftDashboard.vue')
  },
  {
    path: '/subwallet/token/:token',
    name: 'SubAssetDashboard',
    props:true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dashboard" */ '../components/SubAssetDashboard.vue')
  },
  {
    path: '/subwallet/nft/:nft',
    name: 'SubNftDashboard',
    props:true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dashboard" */ '../components/SubNftDashboard.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
